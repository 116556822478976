.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Default styles for stacking components vertically */
.home {
  display: flex;
  flex-direction: column;
}
.home-description {
  width: 100%;
}

.App {
  padding-left: 6vw;
  padding-right: 6vw;
}

.resume:hover {
  background-color: #ffbb00;
  color: "#4f4f51";
  transition: 0.7s;
}

/* Media query for screens with a minimum width of 768px */
@media (min-width: 768px) {
  .home {
    flex-direction: row;
  }
  .home-description {
    align-items: center;
  }
  .App {
    padding-left: 21vw;
    padding-right: 21vw;
  }
  .resume:hover {
    background-color: #ffbb00;
    color: "#4f4f51";
    transition: 0.7s;
  }
}
